.phone-input::-webkit-input-placeholder {
  /* Edge */
  color: rgb(173, 173, 173);
  font-size: 18px;
}

.phone-input :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgb(173, 173, 173);
  font-size: 18px;
}

.phone-input::placeholder {
  color: rgb(173, 173, 173);
  font-size: 18px;
}

.drawer-overlay {
  position: absolute;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99999;
}

.drawer-anim {
  position: absolute;
  width: 100%;
  background-color: transparent;
  height: 100%;
}

.drawer-anim.open {
  bottom: -100%;
  animation-name: float;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.drawer-anim.close {
  bottom: 0%;
  animation-name: float2;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes float {
  0% {
    bottom: -100%;
  }

  100% {
    bottom: 0%;
  }
}

@keyframes float2 {
  0% {
    bottom: 0%;
  }

  100% {
    bottom: -100%;
  }
}

.drawer-scroll-container::-webkit-scrollbar {
  width: 8px;
  cursor: pointer, auto;
}

.drawer-scroll-container::-webkit-scrollbar-track {
  background: rgb(226, 226, 226);
  border-radius: 100vw;
  margin-block: 0.5em;
}

.drawer-scroll-container::-webkit-scrollbar-thumb {
  background: rgb(167, 167, 167);

  border-radius: 100vw;
}

.drawer-scroll-container::-webkit-scrollbar-thumb:hover {
  background: #4596ff;
}
